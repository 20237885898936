import React from 'react'
import Typography from '@material-ui/core/Typography'
import Layout from '../layouts'
import HeaderNavbar from '../components/HeaderNavbar'
import Helmet from "react-helmet";
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "gatsby";
import Box from "@material-ui/core/Box";

export default () => {
    return (
        <Layout>
            <Helmet>
                <title>About Us | Signxperts</title>
            </Helmet>
            <HeaderNavbar/>
            <Container maxWidth='md'>
                <Box marginBottom={5} marginTop={3}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link color='inherit' to='/'>Home</Link>
                        <Typography color='textPrimary'>
                            About Us
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Typography variant={'h4'} gutterBottom>About Us</Typography>
                <Typography gutterBottom>The concept for SIGNXPERTS grew out of our long history in the tradeshow exhibit and retail interior design and manufacturing business.  We have produced award winning custom signage for the past 30 years and have offered our clients the highest level of quality by producing all of the custom fabrication in house.</Typography>
                <Typography gutterBottom>As an extension to our custom signage design and fabrication services, we are now offering expanded signage services to include portable signage solutions for both indoor and outdoor events, retail spaces, schools, resorts, restaurants, malls and anywhere else that the public gathers or passes by.</Typography>
                <Typography gutterBottom>Quality and superior customer service remain the most important things that we can offer our clients, these expanded services will carry the same level of quality that our custom fabricated products do.  We feel strongly about offering our customers more than just great signage and amazing customer service, we also want to educate and inform our clients on all things related to signage. Our goal is to help you find new ways to attract customers for your own business, learn best practices when creating signage and assist you in planning great events.</Typography>
            </Container>
        </Layout>
    )
}
